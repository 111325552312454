import React from 'react';
import { Tab, Nav, Container, Col, Row } from 'react-bootstrap';
import FeatureTabPenLayout from './Components/FeatureTabPenLayout';
import FeatureImage from './Components/FeatureImages';
import Feature from './style';
import featureSectionData from '~data/project/features';
import FeatureWidgetsBlock from './Components/FeatureWidgetsBlock';

import bizImg from '../../../assets/image/for_business.jpg';
import school from '../../../assets/image/for_school.jpg';
import gov from '../../../assets/image/for_gov.jpg';
import campaign from '../../../assets/image/for_campaign.jpg';

export default function FeatureSection() {
  return (
    <Feature background="#136de8">
      <Container className="container">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Feature.Tab>
            <Row className="justify-content-center">
              <Col className="col-xl-7 col-lg-8 col-md-10">
                <Nav className="nav nav-tabs" id="myTab" role="tablist">
                  <Nav.Link eventKey="first" className="nav-link">
                    For Business
                  </Nav.Link>

                  <Nav.Link eventKey="second" className="nav-link">
                    For Schools
                  </Nav.Link>
                  <Nav.Link eventKey="third" className="nav-link">
                    For Government
                  </Nav.Link>

                  <Nav.Link eventKey="fourth" className="nav-link">
                    For Campaigns
                  </Nav.Link>
                </Nav>
              </Col>
            </Row>
            <Tab.Content className="tab-content">
              <Tab.Pane eventKey="first">
                <FeatureTabPenLayout
                  imageBlock={<FeatureImage mainImage={bizImg} />}
                  contentBlock={
                    <FeatureWidgetsBlock
                      widgetData={featureSectionData.tabWidget1}
                    />
                  }
                  contentBlockTitle={featureSectionData.featureTabTitle1}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <FeatureTabPenLayout
                  imageBlock={<FeatureImage mainImage={school} />}
                  contentBlock={
                    <FeatureWidgetsBlock
                      widgetData={featureSectionData.tabWidget2}
                    />
                  }
                  contentBlockTitle={featureSectionData.featureTabTitle2}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <FeatureTabPenLayout
                  imageBlock={<FeatureImage mainImage={gov} />}
                  contentBlock={
                    <FeatureWidgetsBlock
                      widgetData={featureSectionData.tabWidget3}
                    />
                  }
                  contentBlockTitle={featureSectionData.featureTabTitle3}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="fourth">
                <FeatureTabPenLayout
                  imageBlock={<FeatureImage mainImage={campaign} />}
                  contentBlock={
                    <FeatureWidgetsBlock
                      widgetData={featureSectionData.tabWidget4}
                    />
                  }
                  contentBlockTitle={featureSectionData.featureTabTitle4}
                />
              </Tab.Pane>
            </Tab.Content>
          </Feature.Tab>
        </Tab.Container>
      </Container>
    </Feature>
  );
}
