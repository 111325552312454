import React from 'react';
import { StaticImage as Img } from 'gatsby-plugin-image';
import ImageGroup from './style';
const FeatureImages = ({ mainImage }) => {
  return (
    <>
      <ImageGroup>
        <div className="main">
          <img
            style={{ borderRadius: 42 }}
            className="w-100"
            src={mainImage}
            alt="feature tab"
          />
        </div>

        <div className="shape">
          <Img
            src="../../../../../assets/image/marketing/services-shape-l1.png"
            alt="shape"
          />
        </div>
      </ImageGroup>
    </>
  );
};

export default FeatureImages;
