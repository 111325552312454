import {Images} from '~data'
const featureSectionData = {
    featureTabTitle1:"Maintain business continuity when the unexpected happens. Notify your team and get their responses.",
    featureTabTitle2:"Communicate with faculty, staff, parents, and even students quickly and hassle free.",
    featureTabTitle3:"Understand your constituents and keep them informed.",
    featureTabTitle4:"Communicate with your volunteers, donors, and staff seamlessly and in seconds.",
    tabWidget1: [
        {
            icon:Images.projectManagement.featureTwoIcon1,
            iconBackground:"#136de8",
            title:"Expect the Unexpected",
            text:"Make Actovos part of your business continuity plan and keep your entire organization up-to-date.",
        },
        {
            icon:Images.projectManagement.featureTwoIcon2,
            iconBackground:"#136de8",
            title:"Emergency Notification Templates",
            text:"Actovos templates allow for mass notification of thousands of recipients in minutes during an emergency.",
        },
    ],
    tabWidget2: [
        {
            icon:Images.projectManagement.featureTwoIcon1,
            iconBackground:"#136de8",
            title:"Communicate Quickly",
            text:"Use pre-built templates to send out common notifications to all recipients in seconds and gather responses quickly.",
        },
        {
            icon:Images.projectManagement.featureTwoIcon2,
            iconBackground:"#136de8",
            title:"Communicate Intelligently",
            text:"Use Actovos to communicate with your recipients, not just send notifications. Get instant feedback after sending notifications so you can make quick decisions.",
        },
    ],
    tabWidget3: [
        {
            icon:Images.projectManagement.featureTwoIcon1,
            iconBackground:"#136de8",
            title:"Informed Recipients",
            text:"Use Actovos to inform recipients of upcoming events, important dates, potential construction, or emergencies.",
        },
        {
            icon:Images.projectManagement.featureTwoIcon2,
            iconBackground:"#136de8",
            title:"Actovos Analytics",
            text:"Know how many messages are sent, delivery rate and time, and how many people are unaccounted for in a quick glance.",
        },
    ],
    tabWidget4: [
        {
            icon:Images.projectManagement.featureTwoIcon1,
            iconBackground:"#136de8",
            title:"Craft your message",
            text:"Target specific audiences and craft content that converts.",
        },
        {
            icon:Images.projectManagement.featureTwoIcon2,
            iconBackground:"#136de8",
            title:"Gather information",
            text:"Use Actovos to gather information directly from your audience.",
        },
    ],
    

}

export default featureSectionData;